import React from 'react';
import { siteNameEnglish, brandSlogan, siteNameShort } from '../lib/brand';
import Layout from '../components/Layout';
import Header from '../components/Header';

import HomePage from '../components/HomePage';
import Helmet from 'react-helmet';

const Home = () => {
   const header = <Header active={[]} theme="light" />;

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{`${siteNameEnglish} | ${brandSlogan}`}</title>
            <meta
               name="description"
               content={`${siteNameShort} is a full-service home mortgage lender. From first-time home loans to home refinancing, we can help with whatever your home loan needs may be. Contact us today.`}
            />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <HomePage />
      </Layout>
   );
};

export default Home;
